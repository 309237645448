import React from 'react'

const Volume3 = ({ color = '#000', clickFunc = () => {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer w-full h-full"
      onClick={clickFunc}
      viewBox="0 0 13.21 11.919"
    >
      <path
        d="M266.1 571.015v.875a5.164 5.164 0 0 1 0 10.169v.875a6.03 6.03 0 0 0 0-11.919zm-2.131.846-2.983 1.989v6.25l2.983 1.989a.853.853 0 0 0 .852-.852v-8.523a.853.853 0 0 0-.852-.852zm4.687 5.114a2.975 2.975 0 0 0-2.557-2.94v.852a2.131 2.131 0 0 1 0 4.175v.852a2.974 2.974 0 0 0 2.557-2.94zM258 575.27v3.409a.853.853 0 0 0 .852.852h1.278v-5.114h-1.278a.853.853 0 0 0-.852.852z"
        transform="translate(-258 -571.015)"
        style={{ fillRule: 'evenodd', fill: color }}
      />
    </svg>
  )
}

export default Volume3
