import React, { useContext, useEffect } from 'react'
import CustomImage from '../../customImage/customImage'
import { getImage } from '../../../utils/helper'
import { AppContext } from '../../../context/app'

let showLogo = false

const PlayerLogo = ({ playerData, isPlaying }) => {
  let { accountObj } = useContext(AppContext)
  let data = playerData?.screen?.data.logo
  const positionClass = {
    TOP_LEFT: 'left-0',
    TOP_RIGHT: 'right-0',
  }

  useEffect(() => {
    if (isPlaying) {
      showLogo = true
    }
  }, [isPlaying])

  return (
    <div style={{ display: showLogo && data.visible ? 'block' : 'none' }}>
      <div
        className={`absolute top-0 m-2 xl:m-4 ${positionClass[data.position]}`}
        style={{ height: '60px', aspectRatio: '1.777' }}
      >
        <CustomImage
          imageUrl={getImage(accountObj?.cf_domain_name, data.image_s3key)}
          imgClass="w-full h-full "
          loaderClass={'h-full'}
          imgAlt={'thumbnail'}
        />
      </div>
    </div>
  )
}

export default PlayerLogo
