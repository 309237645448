import React from 'react'

const FullScreen2 = ({ isFullScreen = false, color = '#323232' }) => {
  return (
    <div className="h-full w-auto">
      {isFullScreen ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15">
          <path
            data-name="Rectangle 1666"
            style={{ fill: 'rgba(255,255,255,.01)' }}
            d="M0 0h15v15H0z"
          />
          <path
            data-name="Path 1202"
            d="M8.729 9.2a.5.5 0 0 1-.352-.145l-2.729-2.7a.5.5 0 1 1 .7-.711l2.729 2.7a.5.5 0 0 1-.352.855z"
            transform="translate(-4.363 -4.363)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1203"
            d="M6 35.2a.5.5 0 0 1-.352-.855l2.729-2.7a.5.5 0 1 1 .7.711l-2.729 2.7A.5.5 0 0 1 6 35.2z"
            transform="translate(-4.363 -21.531)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1204"
            d="M34.8 35.2a.5.5 0 0 1-.354-.146l-2.7-2.7a.5.5 0 0 1 .707-.707l2.7 2.7a.5.5 0 0 1-.354.854z"
            transform="translate(-21.604 -21.531)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1205"
            d="M32 9.2a.5.5 0 0 1-.354-.854l2.7-2.7a.5.5 0 1 1 .707.707l-2.7 2.7A.5.5 0 0 1 32 9.2z"
            transform="translate(-21.531 -4.363)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1206"
            d="M34.456 9.956H32a.5.5 0 0 1-.5-.5V7a.5.5 0 1 1 1 0v1.956h1.956a.5.5 0 0 1 0 1z"
            transform="translate(-21.531 -5.09)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1207"
            d="M9.456 9.956H7a.5.5 0 0 1 0-1h1.956V7a.5.5 0 0 1 1 0v2.456a.5.5 0 0 1-.5.5z"
            transform="translate(-5.09 -5.09)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1208"
            d="M9.456 34.956a.5.5 0 0 1-.5-.5V32.5H7a.5.5 0 1 1 0-1h2.456a.5.5 0 0 1 .5.5v2.456a.5.5 0 0 1-.5.5z"
            transform="translate(-5.09 -21.531)"
            style={{ fill: color }}
          />
          <path
            data-name="Path 1209"
            d="M32 34.956a.5.5 0 0 1-.5-.5V32a.5.5 0 0 1 .5-.5h2.429a.5.5 0 1 1 0 1H32.5v1.956a.5.5 0 0 1-.5.5z"
            transform="translate(-21.531 -21.531)"
            style={{ fill: color }}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="cursor-pointer w-auto h-full"
          viewBox="0 0 17 17"
        >
          <g data-name="full-screen-one-svgrepo-com (1)">
            <path
              data-name="Rectangle 1665"
              style={{ fill: 'rgba(255,255,255,.01)' }}
              d="M0 0h17v17H0z"
            />
            <path
              data-name="Path 1186"
              d="M9.285 9.752a.5.5 0 0 1-.352-.145L5.648 6.355a.5.5 0 0 1 .7-.711L9.637 8.9a.5.5 0 0 1-.352.855z"
              transform="translate(-4.029 -3.65)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1187"
              d="M6 35.752a.5.5 0 0 1-.352-.855l3.285-3.252a.5.5 0 0 1 .7.711l-3.281 3.251a.5.5 0 0 1-.352.145z"
              transform="translate(-4.029 -21.109)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1188"
              d="M35.352 35.752a.5.5 0 0 1-.352-.147l-3.252-3.252a.5.5 0 0 1 .707-.707l3.251 3.254a.5.5 0 0 1-.354.854z"
              transform="translate(-21.556 -21.109)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1189"
              d="M32 9.752a.5.5 0 0 1-.354-.854L34.9 5.646a.5.5 0 0 1 .707.707l-3.253 3.252a.5.5 0 0 1-.354.147z"
              transform="translate(-21.489 -3.65)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1190"
              d="M35.956 9.456a.5.5 0 0 1-.5-.5V6.5H33a.5.5 0 0 1 0-1h2.956a.5.5 0 0 1 .5.5v2.956a.5.5 0 0 1-.5.5z"
              transform="translate(-22.16 -3.65)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1191"
              d="M35.956 36.456H33a.5.5 0 0 1 0-1h2.456V33a.5.5 0 0 1 1 0v2.956a.5.5 0 0 1-.5.5z"
              transform="translate(-22.16 -21.781)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1192"
              d="M8.956 36.456H6a.5.5 0 0 1-.5-.5V33a.5.5 0 0 1 1 0v2.456h2.456a.5.5 0 0 1 0 1z"
              transform="translate(-4.029 -21.781)"
              style={{ fill: color }}
            />
            <path
              data-name="Path 1193"
              d="M6 9.456a.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5h2.956a.5.5 0 0 1 0 1H6.5v2.456a.5.5 0 0 1-.5.5z"
              transform="translate(-4.029 -3.65)"
              style={{ fill: color }}
            />
          </g>
        </svg>
      )}
    </div>
  )
}

export default FullScreen2
