import React from 'react'

const SeekBar = ({
  handleSeek,
  handleSeekOver,
  convertVideoSecondstoMintues,
  seekBarHoverPercent,
  duration,
  currentTime,
  bufferPercent,
  playerData,
}) => {
  let data = playerData.control_bar.data

  return (
    <div
      className={`w-full cursor-pointer relative `}
      style={{ background: `${data.seek_bar_color}60`, height: '4px' }}
      onClick={(e) => handleSeek(e)}
      onMouseOver={(e) => handleSeekOver(e)}
      title={`${convertVideoSecondstoMintues(Math.floor(seekBarHoverPercent * duration))}`}
    >
      <div
        className=" absolute h-full left-0 top-0"
        style={{
          width: `${(currentTime / duration) * 100}%`,
          background: data.play_progress_bar_color,
          zIndex: 20,
          transition: 'all 300ms ease',
        }}
      >
        {data.seek_bar_type === 'SIMPLE' && <></>}
        {data.seek_bar_type === 'ELLIPSE' && (
          <div
            style={{
              height: '14px',
              width: '6.5px',
              right: 0,
              top: '-5px',
              background: data.seek_bar_handle_color,
              borderRadius: '10px',
            }}
            className="absolute"
          />
        )}
        {data.seek_bar_type === 'RECTANGLE' && (
          <div
            style={{
              height: '10px',
              width: '5px',
              right: 0,
              top: '-3px',
              background: data.seek_bar_handle_color,
            }}
            className="absolute"
          />
        )}
        {data.seek_bar_type === 'ROUND' && (
          <div
            style={{
              height: '10px',
              width: '10px',
              right: 0,
              top: '-3px',
              background: data.seek_bar_handle_color,
            }}
            className="absolute rounded-full"
          />
        )}
      </div>
      <div
        className="absolute h-full left-0 top-0"
        style={{
          width: `${bufferPercent}%`,
          background: '#ffffff30',
          zIndex: 5,
        }}
      />
    </div>
  )
}

export default SeekBar
