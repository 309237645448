import React from 'react'

const Volume1 = ({ color = '#000', clickFunc = () => {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.093 10.84"
      className="cursor-pointer w-full h-full"
      onClick={clickFunc}
    >
      <path
        d="M13.264 4.893a7.62 7.62 0 0 1 0 8.886M10.724 6.8a4.445 4.445 0 0 1 0 5.078M6.845 5.125 4.836 7.134a1.358 1.358 0 0 1-.229.2.635.635 0 0 1-.183.076 1.358 1.358 0 0 1-.306.018h-1.1a1.577 1.577 0 0 0-.669.069.635.635 0 0 0-.277.277A1.577 1.577 0 0 0 2 8.447v1.777a1.577 1.577 0 0 0 .069.669.634.634 0 0 0 .277.277 1.577 1.577 0 0 0 .669.069h1.1a1.355 1.355 0 0 1 .306.018.635.635 0 0 1 .183.076 1.356 1.356 0 0 1 .229.2l2.009 2.009c.272.272.408.408.525.417a.317.317 0 0 0 .266-.11c.076-.089.076-.281.076-.666v-7.7c0-.385 0-.577-.076-.666a.317.317 0 0 0-.266-.11c-.114.01-.25.146-.522.418z"
        transform="translate(-1.3 -3.916)"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.4px',
        }}
      />
    </svg>
  )
}

export default Volume1
