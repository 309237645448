import React from 'react'
import Volume1 from '../../../assets/customPlayer/volume/volume1'
import Volume2Left from '../../../assets/customPlayer/volume/volume2Left'
import Volume2Right from '../../../assets/customPlayer/volume/volume2Right'
import Volume3 from '../../../assets/customPlayer/volume/volume3'

const VolumeBar = (props) => {
  let { toggleMuted, volume, toggleVolume, isMute, playerData } = props

  let data = playerData.control_bar.data.left_control_buttons.volume

  let icons = {
    VOLUME1: (
      <>
        <div style={{ width: '15.9px', height: '11.6px' }}>
          <Volume1 color={data.icon_color} clickFunc={() => toggleMuted(!isMute)} />
        </div>
        <input
          type="range"
          className={`volume-range-bar overflow-hidden`}
          min={0}
          max={100}
          step={2}
          value={volume}
          onChange={(event) => {
            toggleVolume(event.target.valueAsNumber)
          }}
          style={{
            width: `72px`,
            opacity: `0.9`,
            height: '4px',
            marginLeft: '13px',
            //   background: data.seek_color,
            accentColor: data.active_color,
          }}
        />
      </>
    ),
    VOLUME2: (
      <>
        <div style={{ width: '15.9px', height: '11.6px' }}>
          <Volume2Left color={data.icon_color} clickFunc={() => stepVolumeChange(volume - 5)} />
        </div>
        <input
          type="range"
          className={`volume-range-bar overflow-hidden`}
          min={0}
          max={100}
          step={2}
          value={volume}
          onChange={(event) => {
            toggleVolume(event.target.valueAsNumber)
          }}
          style={{
            width: `72px`,
            opacity: `0.9`,
            height: '4px',
            marginLeft: '3px',
            marginRight: '3px',
            //   background: data.seek_color,
            accentColor: data.active_color,
          }}
        />
        <div style={{ width: '15.9px', height: '11.6px' }}>
          <Volume2Right color={data.icon_color} clickFunc={() => stepVolumeChange(volume + 5)} />
        </div>
      </>
    ),
    VOLUME3: (
      <>
        <div style={{ width: '15.9px', height: '11.6px' }}>
          <Volume3 color={data.icon_color} clickFunc={() => toggleMuted(!isMute)} />
        </div>
        <input
          type="range"
          className={`volume-range-bar overflow-hidden`}
          min={0}
          max={100}
          step={2}
          value={volume}
          onChange={(event) => {
            toggleVolume(event.target.valueAsNumber)
          }}
          style={{
            width: `72px`,
            opacity: `0.9`,
            height: '4px',
            marginLeft: '13px',
            //   background: data.seek_color,
            accentColor: data.active_color,
          }}
        />
      </>
    ),
  }

  const stepVolumeChange = (value) => {
    if (value < 0 || value > 100) return
    toggleVolume(value)
  }

  return (
    <div style={{ width: '131px', height: '26px' }}>
      {data.visible ? (
        <div
          className="flex justify-center items-center w-full h-full rounded-full"
          style={{ background: `${data.bg_color}60` }}
        >
          {icons[data.icon_type]}
        </div>
      ) : null}
    </div>
  )
}

export default VolumeBar
