import React from 'react'

const ScreenPauseIcon = ({ primaryColor = '#fff', secondaryColor = '#000000' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 71 71">
      <g data-name="Group 1188">
        <g
          data-name="Ellipse 145"
          style={{ opacity: '.6', stroke: primaryColor, strokeWidth: '4px' }}
        >
          <circle cx="35.5" cy="35.5" r="35.5" style={{ stroke: 'none' }} />
          <circle cx="35.5" cy="35.5" r="33.5" style={{ fill: secondaryColor }} />
        </g>
        <path
          data-name="play-svgrepo-com (2)"
          d="m6.974 3.737 22.463 12.637a1.325 1.325 0 0 1 0 2.308L6.974 31.32A1.325 1.325 0 0 1 5 30.164V4.892a1.325 1.325 0 0 1 1.974-1.154z"
          transform="translate(20 18.432)"
          style={{ fill: primaryColor, opacity: '.6' }}
        />
      </g>
    </svg>
  )
}

export default ScreenPauseIcon
