import React, { useState } from 'react'
import videojs from 'video.js'
import 'videojs-contrib-ads/dist/videojs-contrib-ads.css'
import qualityLevels from 'videojs-contrib-quality-levels'
import hlsQualitySelector from 'videojs-hls-quality-selector'
import './videoJsStyle.css'
import './customVideoJs.css'
import ads from 'videojs-contrib-ads'
// import { AdSecondsConvert, getAdUrl } from '../../utils/helper'
// import { AppContext } from '../../context/app'
import { isEmpty } from 'lodash'
import { useInterval } from '../../hooks/useInterval'
import { convertVideoSecondstoMintues } from './playerHelper'
import { isMobile } from 'react-device-detect'
import SeekBar from './controlBarComponents/seekbar'
import VolumeBar from './controlBarComponents/volumeBar'
import PlayMenu from './controlBarComponents/playMenu'
import SettingBar from './controlBarComponents/settingBar'
import HeaderBar from './headerBar/headerBar'
import DisplayControls from './screenComponents/displayControls'
import PlayerLogo from './screenComponents/playerLogo'
import PlayerWatermark from './screenComponents/playerWatermark'

// let SKIP_SECONDS = 0

const CustomizedVideoJs = (props) => {
  // const { t } = useTranslation()
  const { options, onReady, controlBar, adSetData, playerData, contentInfo } = props

  // const [skipSeconds, setSkipSeconds] = useState(0)
  // const [adDuration, setAdDuration] = useState(0)
  const [currentPlayingAd, setCurrentPlayingAd] = useState({})
  // const { customNavigate } = useTemplateHook()

  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)

  const [duration, setDuration] = useState(null)
  const [currentTime, setCurrentTime] = useState(null)
  const [bufferPercent, setBufferPercent] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(100)
  const [isMute, setIsMute] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isPIPMode, setIsPIPMode] = useState(false)
  const [seekBarHoverPercent, setSeekBarHoverPercent] = useState(0)
  const [showSettingMenu, setShowSettingMenu] = useState(false)
  const [showControlBar, setShowControlBar] = useState(false)
  const [showDisplayIcons, setShowDisplayIcons] = useState(false)
  const [cursorOverControlBar, setCursorOverControlBar] = useState(false)

  React.useEffect(() => {
    videojs.registerPlugin('qualityLevels', qualityLevels)
    videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector)
    videojs.registerPlugin('ads', ads)
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
        handlePlayerReady(player)
        setShowControlBar(true)
        setShowDisplayIcons(true)
      }))
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef, adSetData])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  const handlePlayerReady = (player) => {
    playerRef.current = player

    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('ready', () => {
      player.playsinline(true)
      player.volume(localStorage.getItem('volume') ? localStorage.getItem('volume') : 1)
    })

    player.on('playing', async () => {
      if (isMobile) {
        player.requestFullscreen()
      }
      localStorage.setItem('volume', player.volume())
      setDuration(Math.floor(player.duration()))
      setIsPlaying(true)
    })

    player.on('play', async () => {
      setIsPlaying(true)
    })

    player.on('pause', async () => {
      setIsPlaying(false)
    })

    player.on('timeupdate', function () {
      setCurrentTime(Math.floor(player.currentTime()))
    })

    player.on('fullscreenchange', () => {
      if (player.isFullscreen()) {
        setIsFullScreen(true)
        return
      }
      setIsFullScreen(false)
    })

    player.on('progress', function () {
      const buffPercentage = player.bufferedPercent()
      // console.log('Buffer Percentage: ', buffPercentage * 100)
      setBufferPercent(buffPercentage * 100)
    })

    player.on('enterpictureinpicture', () => {
      setIsPIPMode(true)
    })

    player.on('leavepictureinpicture', () => {
      setIsPIPMode(false)
    })

    player.on('volumechange', () => {
      setVolume(player.volume() * 100)
      localStorage.setItem('volume', player.volume())
    })

    player.on('seeked', async () => {})

    player.on('dispose', async () => {})

    player.on('ended', async () => {})

    player.on('error', () => {})
  }

  const handleSeek = (event) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    let sec = (event.nativeEvent.offsetX / event.target.parentNode.clientWidth) * player.duration()

    player.currentTime(sec)
  }

  const handleSeekOver = (event) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    setSeekBarHoverPercent(event.nativeEvent.offsetX / event.target.parentNode.clientWidth)
  }

  const togglePlayPause = (isPlay) => {
    let player = playerRef.current

    if (!player || !isEmpty(currentPlayingAd)) {
      return
    }
    isPlay ? player.play() : player.pause()
  }

  const toggleFullScreen = (isFull) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    isFull ? player.requestFullscreen() : player.exitFullscreen()
  }

  const togglePIPMode = (isPIP) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    isPIP ? player.requestPictureInPicture() : player.exitPictureInPicture()
  }

  const toggleVolume = (value) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    setIsMute(false)
    player.muted(false)
    player.volume(value / 100)
  }

  const toggleMuted = (status) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    player.volume(status ? 0 : 100)
    player.muted(status)
    setIsMute(status)
  }

  const handleKeyPress = (e) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    if (e.keyCode === 39) {
      player.currentTime(player.currentTime() + 10)
    }
    if (e.keyCode === 37) {
      player.currentTime(player.currentTime() - 10)
    }

    if (e.keyCode === 38) {
      player.volume(player.volume() < 1 ? player.volume() + 0.1 : 1)
    }
    if (e.keyCode === 40) {
      player.volume(player.volume() > 0 ? player.volume() - 0.1 : 0)
    }
  }

  useInterval(
    async () => {
      if (isPlaying && !showSettingMenu && !cursorOverControlBar) {
        setShowControlBar(false)
      }
    },
    !playerData?.control_bar_always_visible && showControlBar ? 4000 : null,
  )

  useInterval(
    async () => {
      if (isPlaying && !showSettingMenu && !cursorOverControlBar) {
        setShowDisplayIcons(false)
      }
    },
    showDisplayIcons ? 4000 : null,
  )

  const forwardClick = () => {
    let player = playerRef.current
    if (!player) {
      return
    }

    player.currentTime(player.currentTime() + 10)
  }
  const backwardClick = () => {
    let player = playerRef.current
    if (!player) {
      return
    }
    player.currentTime(player.currentTime() - 10)
  }

  return (
    <div
      data-vjs-player=""
      className="relative"
      onKeyDown={handleKeyPress}
      tabIndex={-1}
      onMouseMove={() => {
        setShowDisplayIcons(true)
        setShowControlBar(true)
      }}
    >
      {isEmpty(currentPlayingAd) && controlBar && duration && (
        <HeaderBar playerData={playerData} contentInfo={contentInfo} />
      )}
      <div
        style={{ height: 'calc(100% - 102.5px)', marginTop: '46px' }}
        className="text-white w-full absolute top-0 z-20 flex justify-center items-center"
        onClick={controlBar ? () => togglePlayPause(!isPlaying) : null}
      >
        <PlayerLogo playerData={playerData} isPlaying={isPlaying} />
        <PlayerWatermark playerData={playerData} isPlaying={isPlaying} />
        <DisplayControls
          isPlaying={isPlaying}
          playerData={playerData}
          forwardClick={forwardClick}
          backwardClick={backwardClick}
          showDisplayIcons={showDisplayIcons}
        />
      </div>

      <video
        ref={videoRef}
        className={'video-js-custom vjs-fill vjs-big-play-centered vjs-theme-fantasy-custom'}
      />

      {isEmpty(currentPlayingAd) && controlBar && duration && (
        <div
          style={{
            height: showControlBar ? '56.5px' : '0px',
            // backgroundColor: '#00000080',
            transition: 'height 0.25s ease-in',
            padding: '0px 20px',
          }}
          className="text-white w-full absolute bottom-0 "
          onMouseOver={() => setCursorOverControlBar(true)}
          onMouseLeave={() => setCursorOverControlBar(false)}
        >
          <div className="parent-div relative w-full">
            <SeekBar
              handleSeek={handleSeek}
              handleSeekOver={handleSeekOver}
              convertVideoSecondstoMintues={convertVideoSecondstoMintues}
              seekBarHoverPercent={seekBarHoverPercent}
              duration={duration}
              currentTime={currentTime}
              bufferPercent={bufferPercent}
              playerData={playerData}
            />
          </div>
          <div className="w-full flex justify-between items-center mt-2 relative">
            <VolumeBar
              toggleMuted={toggleMuted}
              volume={volume}
              toggleVolume={toggleVolume}
              isMute={isMute}
              playerData={playerData}
            />
            <PlayMenu
              isPlaying={isPlaying}
              togglePlayPause={togglePlayPause}
              currentTime={currentTime}
              duration={duration}
              playerData={playerData}
              forwardClick={forwardClick}
              backwardClick={backwardClick}
            />

            <SettingBar
              playerData={playerData}
              isPIPMode={isPIPMode}
              togglePIPMode={togglePIPMode}
              isFullScreen={isFullScreen}
              toggleFullScreen={toggleFullScreen}
              showSettingMenu={showSettingMenu}
              setShowSettingMenu={setShowSettingMenu}
              playerRef={playerRef}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomizedVideoJs
