import React, { useContext } from 'react'
import { TemplateContext } from '../../context/template'
import { isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'
import CustomPlayer from '../player/customPlayer'
import NewCustomizedPlayer from './newCustomizedPlayer'

const PlayerHome = (props) => {
  const { playerTemplateData, playerTemplateLoaded } = useContext(TemplateContext)
  const {
    contentInfo,
    contentWatchUrl,
    resumeTime,
    handlePlayingSequence,
    disableHeader,
    loop,
    controlBar,
    adCampaignData,
    onVideoComplete,
  } = props

  return (
    <div className="w-full h-full">
      {playerTemplateLoaded ? (
        !`${contentInfo.video_type}`.includes('LIVE') &&
        !isMobile &&
        !isEmpty(playerTemplateData?.data) ? (
          <NewCustomizedPlayer
            contentInfo={contentInfo}
            contentWatchUrl={contentWatchUrl}
            resumeTime={resumeTime}
            handlePlayingSequence={handlePlayingSequence}
            adCampaignData={adCampaignData}
            onVideoComplete={onVideoComplete}
            playerData={playerTemplateData?.data}
          />
        ) : (
          <CustomPlayer
            contentInfo={contentInfo}
            contentWatchUrl={
              contentInfo.video_type === 'LIVE' ? contentInfo.stream.link : contentWatchUrl
            }
            resumeTime={resumeTime}
            handlePlayingSequence={handlePlayingSequence}
            disableHeader={disableHeader}
            loop={loop}
            controlBar={controlBar}
            adCampaignData={adCampaignData}
            onVideoComplete={onVideoComplete}
          />
        )
      ) : null}
    </div>
  )
}

export default PlayerHome
