import React from 'react'
import ScreenPauseIcon from '../../../assets/customPlayer/screenIcons/screenPauseIcon'
import ScreenBackIcon from '../../../assets/customPlayer/screenIcons/screenBackIcon'
import ScreenForwardIcon from '../../../assets/customPlayer/screenIcons/screenForwardIcon'

const DisplayControls = ({
  isPlaying,
  playerData,
  backwardClick,
  forwardClick,
  showDisplayIcons,
}) => {
  let data = playerData?.screen?.data.control_icons

  const handleForwardBackward = (e, type) => {
    e.stopPropagation()
    if (type === 'back') {
      backwardClick()
    } else {
      forwardClick()
    }
  }

  return (
    <div
      style={{ marginTop: '10.5px', width: '303px' }}
      className={`hidden ${data.visible && 'xl:flex'} ${
        !data.show_forward_backward ? 'justify-center' : 'justify-between'
      } items-center`}
    >
      {data.show_forward_backward ? (
        <div
          style={{
            width: '45px',
            height: '49px',
            opacity: !isPlaying || showDisplayIcons ? '1' : '0',
            transition: 'opacity 0.25s ease-in',
          }}
          className="z-20 cursor-pointer"
          onClick={(e) => handleForwardBackward(e, 'back')}
        >
          <ScreenBackIcon primaryColor={data.primary_color} />
        </div>
      ) : null}
      {data.show_play_pause && !isPlaying ? (
        <div style={{ width: '70px', height: '70px' }} className="z-20 cursor-pointer">
          <ScreenPauseIcon
            primaryColor={data.primary_color}
            secondaryColor={data.secondary_color}
          />
        </div>
      ) : null}

      {data.show_forward_backward ? (
        <div
          style={{
            width: '45px',
            height: '49px',
            opacity: !isPlaying || showDisplayIcons ? '1' : '0',
            transition: 'opacity 0.25s ease-in',
          }}
          className="z-20 cursor-pointer"
          onClick={(e) => handleForwardBackward(e, 'forward')}
        >
          <ScreenForwardIcon primaryColor={data.primary_color} />
        </div>
      ) : null}
    </div>
  )
}

export default DisplayControls
