import React from 'react'
import BackwardButton from './backwardButton'
import ForwardButton from './forwardButton'
import PlayPauseButton from './playPauseButton'
import { convertVideoSecondstoMintues } from '../playerHelper'

const PlayMenu = (props) => {
  let {
    isPlaying,
    togglePlayPause,
    currentTime,
    duration,
    playerData,
    backwardClick,
    forwardClick,
  } = props

  let playPauseData = playerData.control_bar.data.middle_control_buttons.play_pause
  let backwardData = playerData.control_bar.data.middle_control_buttons.backward_forward
  let playTimeData = playerData.control_bar.data.middle_control_buttons.play_time

  return (
    <div
      style={{ fontFamily: playTimeData.font_style, color: playTimeData.text_color }}
      className={`flex items-center text-xs ${playTimeData.font_format}`}
    >
      {playTimeData.visible ? (
        <p style={{ marginRight: 'min(1.4vw , 26px)' }}>
          {convertVideoSecondstoMintues(currentTime)}
        </p>
      ) : null}

      <div>
        <BackwardButton data={backwardData} backwardClick={backwardClick} />
      </div>
      <div style={{ margin: '0 min(1.2vw , 20px)' }}>
        <PlayPauseButton
          isPlaying={isPlaying}
          togglePlayPause={togglePlayPause}
          data={playPauseData}
        />
      </div>
      <div>
        <ForwardButton data={backwardData} forwardClick={forwardClick} />
      </div>

      {playTimeData.visible ? (
        <p style={{ marginLeft: 'min(1.4vw , 26px)' }}>{convertVideoSecondstoMintues(duration)}</p>
      ) : null}
    </div>
  )
}

export default PlayMenu
