import React from 'react'
import FullScreen1 from '../../../assets/customPlayer/fullScreen/fullScreen1'
import FullScreen2 from '../../../assets/customPlayer/fullScreen/fullScreen2'

const FullScreenButton = (props) => {
  let { isFullScreen, toggleFullScreen, data } = props

  let icons = {
    FULL_SCREEN1: (
      <>
        <FullScreen1 isFullScreen={isFullScreen} color={data.icon_color} />
      </>
    ),
    FULL_SCREEN2: (
      <>
        <FullScreen2 isFullScreen={isFullScreen} color={data.icon_color} />
      </>
    ),
    FULL_SCREEN3: <></>,
    FULL_SCREEN4: <></>,
    FULL_SCREEN5: <></>,
    FULL_SCREEN6: <></>,
  }

  return (
    <div className="h-full" style={{ padding: '2px 0' }}>
      {data.visible ? (
        <div
          className="flex justify-center items-center w-auto h-full cursor-pointer"
          onClick={() => toggleFullScreen(!isFullScreen)}
        >
          {icons[data.icon_type]}
        </div>
      ) : null}
    </div>
  )
}

export default FullScreenButton
